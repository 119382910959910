.container {
  width: 100vw;
  background-color: black;
  column-count: 2;
  min-height: 100vh;
}

.container .item {
  cursor: pointer;
}

img {
  width: 100%;
}

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: black;
}


@media screen and (min-width: 600px) {
  .container {
    width: 50%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}